import { FormikErrors } from 'formik';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import AsyncReactSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { ApiCore } from '@healthme/services/api/utilities/Core';
import { debounce } from '@material-ui/core';

interface AsyncSelectProps {
	name: string;
	error: string | FormikErrors<any> | string[] | FormikErrors<any>[];
	value: string;
	ref?: any;
	placeholder: string;
	apiPath: string;
	valueKey: string;
	filterKey?: string;
	options?: any[];
	label?: string;
	labelClassName?: string;
	className?: string;
	isCreatable?: boolean;
	isMulti?: boolean;
	disabled?: boolean;
	inputCreateLabel?: string;
	initialize?: boolean;
	menuPlacement?: 'top' | 'bottom';
	controlStyles?: Record<string, any>;
	formatter: (item: any) => { value: string; label: string };
	onChange: (val: any) => void;
}
const api = new ApiCore({
	url: '',
});

const AsyncSelect: React.FC<AsyncSelectProps> = forwardRef(
	({
		ref = null,
		options = [],
		error,
		value,
		apiPath,
		name,
		placeholder,
		label,
		initialize = true,
		inputCreateLabel = 'Create',
		className = '',
		filterKey = 'filter',
		labelClassName = 'block mb-1 font-semibold',
		valueKey = 'value',
		isCreatable = false,
		isMulti = false,
		disabled = false,
		controlStyles = {},
		menuPlacement = 'bottom',
		formatter,
		onChange,
	}) => {
		const [defaultOptions, setDefaultOptions] = useState<any[]>(options);
		const [selectedValue, setSelectedValue] = useState<any>(value);
		const [loading, setLoading] = useState(true);
		const [searchError, setSearchError] = useState<string>('');
		const SelectComponent = isCreatable ? AsyncCreatableSelect : AsyncReactSelect;

		useEffect(() => {
			setLoading(true);
			if (!initialize) return;
			api.getRaw(`${apiPath}`).then(resp => {
				if (Array.isArray(resp))
					setDefaultOptions(resp.slice(0, 50).map(formatter).filter(Boolean));
				else setDefaultOptions(resp.data.slice(0, 50).map(formatter).filter(Boolean));
				setLoading(false);
			});
		}, []);
		useEffect(() => {
			if (typeof selectedValue === 'string' && selectedValue !== '') {
				api.getRaw(`${apiPath}?${filterKey}=${selectedValue}`).then(resp => {
					if (resp.length) {
						setSelectedValue(formatter(resp.find(e => e[valueKey] === selectedValue)));
					}
				});
			}
		}, [selectedValue, value]);
		const loadOptions = useCallback(
			debounce((inputValue: string, callback: (options: any) => void) => {
				if (inputValue.length <= 3) {
					callback([]);
					return;
				}

				api.getRaw(`${apiPath}?${filterKey}=${inputValue}`)
					.then(resp => {
						const createOption = {
							label: formatCreateLabel(inputValue),
							value: inputValue,
							__isNew__: true,
						};
						callback(() => {
							setSearchError('');
							if (typeof resp?.data === 'string') {
								setLoading(false);
								try {
									const parsed = JSON.parse(resp);
									setSearchError(parsed.error.replace('filter', inputValue));
								} catch (error) {
									setSearchError('Please be more specific with your search.');
								}
								return [];
							}
							console.log(resp);
							let options = Array.isArray(resp)
								? resp.map(formatter)
								: resp?.data?.map(formatter) || [];
							if (isCreatable) options = [createOption, ...options];
							return options;
						});
					})
					.catch(err => {
						console.log(err);
					});
			}, 1000),
			[]
		);
		const formatCreateLabel = inputValue => `${inputCreateLabel} "${inputValue}"`;
		const handleClear = () => {
			if (ref.current) {
				ref.current.clearValue();
			}
		};

		useImperativeHandle(ref, () => ({
			handleClear,
		}));

		return (
			<>
				{label ? <label className={labelClassName}>{label}</label> : null}
				<SelectComponent
					ref={ref}
					isDisabled={disabled}
					isClearable
					styles={{
						control: base => ({
							...base,
							borderColor: 'rgb(117, 117, 117)',
							borderWidth: '1px',
							boxShadow: 'none',
							padding: '5.5px 7px',
							...controlStyles,
						}),
						menu: base => ({
							...base,
							zIndex: 9999,
							maxHeight: '200px', // Set the maximum height of the dropdown
							overflowY: 'auto', // Enable vertical scrolling if content exceeds maxHeight
						}),
					}}
					classNames={{
						control: state =>
							`w-full rounded text-left  shadow-sm ring-1 ring-inset  focus:outline-none focus:ring-1 ${
								error
									? 'text-red-900 focus:ring-red-500 ring-red-300 border-red-500  placeholder:text-red-300 border'
									: 'border-gray-400 border ring-gray-300 placeholder:text-gray-400  text-gray-900'
							} ${className}`,
					}}
					options={[]}
					name={name}
					placeholder={placeholder}
					value={selectedValue}
					onChange={(e: any) => {
						setSelectedValue(!e ? { value: null } : e);
						onChange(!e ? { value: null } : e);
					}}
					cacheOptions
					loadOptions={loadOptions}
					defaultOptions={defaultOptions}
					isLoading={loading}
					isMulti={isMulti}
					menuPlacement={menuPlacement}
				/>
				{searchError && <span className="text-sm text-red-500">{searchError}</span>}
			</>
		);
	}
);


export default AsyncSelect;
