import moment from 'moment';

import { ApiCore } from '@healthme/services/api/utilities/Core';
import { apiProvider } from '@healthme/services/api/utilities/Provider';
import { capitalize, centsToDollars } from '@healthme/utility/Utils';

var dateFormat = require('dateformat');

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - Purchaces Class
 **-------------------------------------------------------------------------------------
 */
class Purchases extends ApiCore {
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - purchases
	 **-------------------------------------------------------------------------------------
	 */
	purchases({
		markplace_id = 'all',
		time = '',
		provider = [],
		packages = [],
		patient = '',
		dateFilterType = '',
		dateFilter = '',
		endDateFilter = '',
		isNetworkCall = false,
		path = 'purchases?',

		status = '',
	}) {
		let subtraction: any = 1;

		try {
			let filters: any[] = [];
			if (markplace_id !== 'all' && markplace_id.length) {
				filters.push(`purchases.marketplace_id[eq]=${markplace_id}`);
			}
			if (time && time.length && time !== 'all') {
				if (time === 'custom' && dateFilter.length) {
					filters.push(
						`${dateFilterType}[gte]=$${moment(dateFilter)
							.startOf('day')
							.format('YYYY-MM-DD')}T00:00:00.000Z`
					);
					filters.push(
						`${dateFilterType}[lt]=${moment(endDateFilter || dateFilter)
							.endOf('day')
							.format('YYYY-MM-DD')}T23:59:59.000Z`
					);
				} else {
					if (time == 'day') subtraction = 0;
					if (time == 'yesterday') {
						subtraction = 1;
						time = 'day';
					}
					filters.push(
						`${dateFilterType}[${dateFilter.length ? 'gt' : 'gte'}]=${
							dateFilter.length
								? moment(dateFilter).startOf('day').format('YYYY-MM-DD') +
								  'T00:00:00.000Z'
								: moment().subtract(subtraction, time).format('YYYY-MM-DD') +
								  'T00:00:00.000Z'
						}`
					);
				}
			}

			if (provider && provider.length > 0) {
				filters.push(`service_provider_id[in]=${provider.join(',')}`);
			}

			if (packages && packages.length > 0) {
				filters.push(`package.type[in]=${packages.join(',')}`);
			}
			if (patient && patient.length > 0) {
				filters.push(`patient_account_id[eq]=${patient}`);
			}
			if (status && status !== 'all' && status.length) {
				filters.push(`status[eq]=${status}`);
			}
			return `${path}${filters.join('&')}`;
		} catch (error) {
			console.log(error);
		}
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - purchases
	 **-------------------------------------------------------------------------------------
	 */
	carenavPurchases(
		{
			timeFrameFilter = '',
			dateFilterType = '',
			dateFilter = '',
			endDateFilter = '',
			assigneeFilter = [],
			marketplaceFilter = 'all',
			organizationFilter = 'all',
			providerFilter = [],
			patientFilter = '',
			statusFilter = '',
			groupFilter = '',
			packageFilter = [],
		},
		path = 'purchases?'
	) {
		let subtraction: any = 1;

		try {
			let filters: any[] = [];
			if (marketplaceFilter !== 'all' && marketplaceFilter.length) {
				filters.push(`purchases.marketplace_id[eq]=${marketplaceFilter}`);
			}
			if (organizationFilter !== 'all' && organizationFilter.length) {
				filters.push(`purchases.organization_id[eq]=${organizationFilter}`);
			}

			if (timeFrameFilter && timeFrameFilter.length && timeFrameFilter !== 'all') {
				if (timeFrameFilter === 'custom' && dateFilter.length) {
					filters.push(
						`${dateFilterType}[gte]=${moment(dateFilter)
							.startOf('day')
							.format('YYYY-MM-DD')}T00:00:00.000Z`
					);
					filters.push(
						`${dateFilterType}[lt]=${moment(endDateFilter || dateFilter)
							.endOf('day')
							.format('YYYY-MM-DD')}T23:59:59.000Z`
					);
				} else {
					if (timeFrameFilter == 'day') subtraction = 0;
					if (timeFrameFilter == 'yesterday') {
						subtraction = 1;
						timeFrameFilter = 'day';
					}
					filters.push(
						`${dateFilterType}[${dateFilter.length ? 'gt' : 'gte'}]=${
							dateFilter.length
								? moment(dateFilter).startOf('day').format('YYYY-MM-DD') +
								  'T00:00:00.000Z'
								: moment()
										.subtract(subtraction, timeFrameFilter)
										.format('YYYY-MM-DD') + 'T00:00:00.000Z'
						}`
					);
				}
			}

			if (groupFilter !== 'all' && groupFilter.length) {
				filters.push(`group_id[eq]=${groupFilter}`);
			}
			if (statusFilter && statusFilter !== 'all' && statusFilter.length) {
				filters.push(`status[eq]=${statusFilter}`);
			}

			if (providerFilter && providerFilter.length > 0) {
				filters.push(`service_provider_id[in]=${providerFilter.join(',')}`);
			}

			if (patientFilter && patientFilter.length > 0) {
				filters.push(`purchases.patient_account_id[eq]=${patientFilter}`);
			}
			if (packageFilter && packageFilter.length > 0) {
				filters.push(`package.type[in]=${packageFilter.join(',')}`);
			}
			if (assigneeFilter && assigneeFilter.length) {
				filters.push(`network_organization_assignees[in]=${assigneeFilter.join(',')}`);
			}
			filters.push('network=true');
			return `${path}${filters.join('&')}`;
		} catch (error) {
			console.log(error);
		}
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - purchases
	 **-------------------------------------------------------------------------------------
	 */
	paylinkPurchases(
		{
			timeFrameFilter = '',
			dateFilterType = '',
			dateFilter = '',
			endDateFilter = '',
			patientFilter = '',
			typeFilter = '',
			statusFilter = '',
		},
		path = 'paylink?'
	) {
		let subtraction: any = 1;
		try {
			let filters: any[] = [];
			if (timeFrameFilter && timeFrameFilter.length && timeFrameFilter !== 'all') {
				if (timeFrameFilter === 'custom' && dateFilter.length) {
					filters.push(
						`${dateFilterType}[gte]=${moment(dateFilter)
							.startOf('day')
							.format('YYYY-MM-DD')}T00:00:00.000Z`
					);
					filters.push(
						`${dateFilterType}[lt]=${moment(endDateFilter || dateFilter)
							.endOf('day')
							.format('YYYY-MM-DD')}T23:59:59.000Z`
					);
				} else {
					if (timeFrameFilter == 'day') subtraction = 0;
					if (timeFrameFilter == 'yesterday') {
						subtraction = 1;
						timeFrameFilter = 'day';
					}
					filters.push(
						`${dateFilterType}[${dateFilter.length ? 'gt' : 'gte'}]=${
							dateFilter.length
								? moment(dateFilter).startOf('day').format('YYYY-MM-DD') +
								  'T00:00:00.000Z'
								: moment()
										.subtract(subtraction, timeFrameFilter)
										.format('YYYY-MM-DD') + 'T00:00:00.000Z'
						}`
					);
				}
			}
			if (statusFilter !== 'all' && statusFilter.length) {
				filters.push(`status[eq]=${statusFilter}`);
			}
			if (typeFilter !== 'all' && typeFilter.length) {
				filters.push(`type[eq]=${typeFilter}`);
			}

			if (patientFilter && patientFilter.length > 0) {
				filters.push(`patient_account_id[eq]=${patientFilter}`);
			}
			return `${path}${filters.join('&')}`;
		} catch (error) {
			console.log(error);
		}
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getMore Rows
	 **-------------------------------------------------------------------------------------
	 */
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getCSV
	 **-------------------------------------------------------------------------------------
	 */
	getCSV(
		path = 'purchases/export/csv',
		time,
		dateFilterType = 'created_at',
		dateFilter,
		endDateFilter,
		isNetworkCall = true
	) {
		let subtraction = 1;

		let filters: any[] = [];
		if (time !== 'all') {
			if (time === 'custom' && dateFilter.length) {
				filters.push(
					`${dateFilterType}[gte]=${
						moment(dateFilter).endOf('day').format('YYYY-MM-DD') + 'T00:00:00.005Z'
					}`
				);
				filters.push(
					`${dateFilterType}[lt]=${
						moment(endDateFilter).endOf('day').format('YYYY-MM-DD') + 'T23:59:59.005Z'
					}`
				);
			} else {
				if (time == 'day') subtraction = 0;
				if (time == 'yesterday') {
					subtraction = 1;
					time = 'day';
				}
				filters.push(
					`${dateFilterType}[${dateFilter.length ? 'gt' : 'gte'}]=${
						dateFilter.length
							? moment(dateFilter).startOf('day').format('YYYY-MM-DD') +
							  'T00:00:00.005Z'
							: moment().subtract(subtraction, time).format('YYYY-MM-DD') +
							  'T00:00:00.005Z'
					}`
				);
			}
		}

		if (!time) return apiProvider.getAll(path);
		return apiProvider.getAll(path + `?${filters.join('&')}`);
	}

	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - formatForPaymentsTable
	 **-------------------------------------------------------------------------------------
	 */

	formatForPaymentsTable(data): any[] {
		return data.map(item => {
			return {
				id: item.id,
				_icon:
					item?.notes && item?.notes?.length
						? item.notes[item?.notes?.length - 1].message
						: false,
				created_at: dateFormat(item.created_at, 'paddedShortDate'),
				redeemed:
					item.redeemed_at === null
						? ''
						: dateFormat(item.redeemed_at, 'paddedShortDate'),
				package: item.claimed_package.title,
				provider: `${item?.service_provider?.last_name}, ${item?.service_provider?.first_name}`,
				status: item.status.replace(/_/gi, ' '),
				patient: `${item.patient_account.patient_last_name}, ${item.patient_account.patient_first_name}`,
				patient_dob: `${item.patient_account.patient_date_of_birth}`,
				billing: `${item.billing_first_name} ${item.billing_last_name}`,
				member_id: item.patient_account_id,
				provider_id: item.service_provider_id,
				package_id: item.claimed_package_id,
				disbursement_code: item.disbursement_code,
				network: item?.network?.name,
				disbursed_at:
					item.disbursed_at === null
						? ''
						: dateFormat(item.disbursed_at, 'paddedShortDate'),
				offer_price: `$${centsToDollars(item.amount - item.service_fee_amount).replace(
					/(\d)(?=(\d\d\d)+(?!\d))/g,
					'$1,'
				)}`,
				list_price: `$${centsToDollars(item.amount).replace(
					/(\d)(?=(\d\d\d)+(?!\d))/g,
					'$1,'
				)}`,
				vcc_id: item.vcc_id,
				marketplace: item.marketplace.name,
				payment_type:
					item.payment_type && item.payment_type.length
						? item.payment_type.replace(/_/gi, ' ')
						: 'N/A',
				redemption_code: item.redemption_code,
				appointment_at: !item?.appointment_at
					? ''
					: moment(item.appointment_at).add(1, 'day').format('YYYY-MM-DD'),
				group: item?.group?.group_name,
				ehr_patient_id: item?.ehr_patient_id,
				redeemed_by: `${item?.redeemed_by_staff_account?.first_name || ''} ${
					item?.redeemed_by_staff_account?.last_name || ''
				}`,
				organization: item?.organization?.name || 'N/A',
				referral: item?.public_app ? 'CareNav' : 'Direct',
				claim_status: item?.claim_status,
				patient_responsibility_status:
					item.patient_responsibility_status && item.patient_responsibility_status.length
						? capitalize(item.patient_responsibility_status.replace(/_/gi, ' '))
						: '',
				patient_guarantor_amount:
					item?.patient_guarantor_amount === undefined
						? ''
						: `$${centsToDollars(item?.patient_guarantor_amount || 0).replace(
								/(\d)(?=(\d\d\d)+(?!\d))/g,
								'$1,'
						  )}`,

				rest: { ...item },
			};
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - formatForPaymentsTable
	 **-------------------------------------------------------------------------------------
	 */

	formatForNetworkPaymentsTable(data): any[] {
		return data.map(item => {
			return {
				id: item.id,
				_icon:
					item?.notes && item?.notes?.length
						? item.notes[item?.notes?.length - 1].message
						: false,
				created_at: dateFormat(item.created_at, 'paddedShortDate'),
				redeemed:
					item.redeemed_at === null
						? ''
						: dateFormat(item.redeemed_at, 'paddedShortDate'),
				package: item.claimed_package.title,
				provider: `${item?.service_provider?.last_name}, ${item?.service_provider?.first_name}`,
				status: item.status.replace(/_/gi, ' '),
				patient: `${item.patient_account.patient_last_name}, ${item.patient_account.patient_first_name}`,
				patient_dob: `${item.patient_account.patient_date_of_birth}`,
				billing: `${item.billing_first_name} ${item.billing_last_name}`,
				member_id: item.member_id,
				provider_id: item.service_provider_id,
				package_id: item.claimed_package_id,
				disbursement_code: item.disbursement_code,
				network: item?.network?.name,
				disbursed_at:
					item.disbursed_at === null
						? ''
						: dateFormat(item.disbursed_at, 'paddedShortDate'),
				offer_price: `$${centsToDollars(item.amount - item.service_fee_amount).replace(
					/(\d)(?=(\d\d\d)+(?!\d))/g,
					'$1,'
				)}`,
				list_price: `$${centsToDollars(item.amount).replace(
					/(\d)(?=(\d\d\d)+(?!\d))/g,
					'$1,'
				)}`,
				vcc_id: item.vcc_id,
				marketplace: item.marketplace.name,
				payment_type:
					item.payment_type && item.payment_type.length
						? item.payment_type.replace(/_/gi, ' ')
						: 'N/A',
				redemption_code: item.redemption_code,
				appointment_at: !item?.appointment_at
					? ''
					: moment(item.appointment_at).add(1, 'day').format('YYYY-MM-DD'),
				group: item?.group?.group_name,
				ehr_patient_id: item?.ehr_patient_id,
				organization: item?.organization?.name || 'N/A',
				assignees:
					item?.network_organization_assignees
						?.map(e => `${e.first_name} ${e.last_name}`)
						.join(', ') || '',
				patient_responsibility_status:
					item.patient_responsibility_status && item.patient_responsibility_status.length
						? capitalize(item.patient_responsibility_status.replace(/_/gi, ' '))
						: '',
				patient_guarantor_amount:
					item?.patient_guarantor_amount === undefined
						? ''
						: `$${centsToDollars(item?.patient_guarantor_amount || 0).replace(
								/(\d)(?=(\d\d\d)+(?!\d))/g,
								'$1,'
						  )}`,
				claim_status: item?.claim_status,
				rest: { ...item },
			};
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - formatForPaymentsTable
	 **-------------------------------------------------------------------------------------
	 */

	formatForCopaysTable(data): any[] {
		return data.map(item => {
			return {
				...item,
				id: item.id,
				amount: `$${centsToDollars(item.amount).replace(
					/(\d)(?=(\d\d\d)+(?!\d))/g,
					'$1,'
				)}`,
				created_at:
					item.created_at === null ? '' : dateFormat(item.created_at, 'paddedShortDate'),
				date_paid:
					item.created_at === null ? '' : dateFormat(item.created_at, 'paddedShortDate'),
				disbursed_at:
					item.disbursed_at === null
						? ''
						: dateFormat(item.disbursed_at, 'paddedShortDate'),
				patient_date_of_birth: item.patient_account?.patient_date_of_birth,
				organization: item?.organization?.name || 'N/A',
				payment_type: item.payment_type.replace(/_/gi, ' '),
				sent:
					item.created_at === null ? '' : dateFormat(item.created_at, 'paddedShortDate'),
				rest: { ...item },
			};
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - formatForPaymentsTable
	 **-------------------------------------------------------------------------------------
	 */

	formatForSentPaylinksTable(data): any[] {
		return data.map(item => {
			return {
				...item,
				id: item.id,
				paylink_amount: `$${centsToDollars(item.paylink_amount).replace(
					/(\d)(?=(\d\d\d)+(?!\d))/g,
					'$1,'
				)}`,
				created_at:
					item.created_at === null ? '' : dateFormat(item.created_at, 'paddedShortDate'),
				expires_at:
					item.expires_at === null ? '' : dateFormat(item.expires_at, 'paddedShortDate'),
				last_reminder_sent_at:
					item.last_reminder_sent_at === null
						? ''
						: dateFormat(item.last_reminder_sent_at, 'paddedShortDate'),
				patient_date_of_birth: item.paylink_note,
				paylink_note: item?.paylink_note || 'N/A',
				paylink_type: item.paylink_type.replace(/_/gi, ' '),
				rest: { ...item },
			};
		});
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getPatientPurchases
	 **-------------------------------------------------------------------------------------
	 */
	getPatientPurchases(patientId) {
		return this.get(`?patient_account_id=${patientId}`);
	}
	/*
	 **-------------------------------------------------------------------------------------
	 ** FN NAME - getOptions
	 **-------------------------------------------------------------------------------------
	 */
	getOptions(data, key) {
		return data
			.map(item => {
				return { value: item[key + '_id'], label: item[key] };
			})
			.filter(
				(item, index, self) =>
					index === self.findIndex(t => t.value === item.value && t.value === item.value)
			)
			.filter(item => item.value !== null);
	}
}

/*
 **-------------------------------------------------------------------------------------
 ** METHOD NAME - Purchaces
 **-------------------------------------------------------------------------------------
 */
const purchases = new Purchases({
	url: 'purchases',
});

export default purchases;
